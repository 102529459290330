import _ from 'lodash';
import { isInternalUser } from 'states/profile/business-rules';
import flags from 'containers/flags/service';
import { getEnvironmentSettings } from 'services/environment';

export const metrics = [
    'impressions',
    'clicks',
    'ctr',
    'placed_bid',
    'win_rate',
    'owner_total_media_cost_local',
    'spend',
    'ecpm',
    'ecpc',
    'revenue',
    'erpm',
    'erpc',
    'unique_users',
    'margin',
];

export const metricsWithSpendBreakout = [
    'impressions',
    'clicks',
    'ctr',
    'owner_inventory_cost_local',
    'owner_media_cost_2_local',
    'data_cost',
    'spend',
    'third_party_fees',
    'owner_total_media_cost_local',
    'owner_total_media_cost_local_ecpm',
    'owner_total_media_cost_local_ecpc',
    'owner_total_media_cost_local_ecpcv',
    'revenue',
    'erpm',
    'erpc',
    'revenue_ecpcv',
    'unique_users',
    'placed_bid',
    'win_rate',
    'margin',
    'addictive_cost_local',
    'addictive_cost_cad',
    'addictive_cost_usd',
    'addictive_revenue_cad',
    'internal_margin_cad',
];

export const dimensions = [
    'campaign_id',
    'ad_id',
    'hour',
    'date',
    'week_by_monday_to_sunday',
    'month',
    'quarter',
    'device_os',
    'geo_country',
    'geo_country_region',
    'age',
    'gender',
    'markup_id',
    'audience_segment',
    'tactic_id',
    'platform',
    'flight_id',
];

export const dimensionCategoryMap = {
    campaign_id: [
        'campaign_id_attr',
        'campaign_name_attr',
        'campaign_currency_attr',
        'partner_attr',
        'advertiser_attr',
        'advertiser_domain_attr',
        'advertiser_category_attr',
        'sales_rep_attr',
        'campaign_custom_field',
    ],
    ad_id: [
        'ad_id_attr',
        'ad_name_attr',
        'ad_start_attr',
        'ad_end_attr',
        'ad_notes_attr',
        'primary_ad_group_attr',
        'billing_rate_attr',
        'billable_impressions_attr',
        'added_value_impressions_attr',
        'billable_clicks_attr',
        'added_value_clicks_attr',
        'lifetime_impressions',
        'ad_platform_attr',
        'lifetime_clicks',
        'lifetime_spend',
        'lifetime_billings_local',
        'ad_custom_field',
    ],
    markup_id: [
        'creative_id_attr',
        'creative_name_attr',
        'creative_format_attr',
        'creative_type_attr',
        'creative_channel_attr',
        'creative_size_attr',
        'creative_vendors_attr',
    ],
    audience_segment: ['audience_segment_type_attr'],
    camp_org: ['organization_type_attr'],
};

export const categories = {
    general: {
        label: 'General',
        dimensions: ['campaign_id', 'ad_id', 'markup_id', 'tactic_id', 'platform', 'camp_org'],
    },
    date_time: {
        label: 'Date / Time',
        dimensions: ['date', 'hour', 'week_by_monday_to_sunday', 'month', 'quarter'],
    },
    device: {
        label: 'Device',
        dimensions: ['device_os', 'geo_country', 'geo_country_region'],
    },
    user: {
        label: 'User',
        dimensions: ['audience_segment', 'age', 'gender'],
    },
};

export const categoriesSnowFlake = {
    general: {
        label: 'General',
        dimensions: [
            'campaign_id',
            'ad_id',
            'markup_id',
            'creative_size',
            'creative_channel',
            'tactic_id',
            'platform',
            'camp_org',
            'currency',
            'flight_id',
            'video_length',
        ],
    },
    date_time: {
        label: 'Date / Time',
        dimensions: ['date', 'hour', 'week_by_monday_to_sunday', 'month', 'quarter'],
    },
    device: {
        label: 'Device',
        dimensions: [
            'device_os',
            'device_model',
            'device_type_name',
            'device_language',
            'device_carrier',
        ],
    },
    geo: {
        label: 'Geo',
        dimensions: ['geo_country', 'geo_country_region', 'geo_city', 'fsa'],
    },
    inventory: {
        label: 'Inventory',
        dimensions: [
            'exchange_id',
            'inventory_type',
            'inventory_id',
            'inventory_name',
            'app_store_cat',
            'pmp_id',
            'pmp_deal_name',
            'video_placement_name',
            'video_position_name',
            'video_skippable',
            'dooh_venue_type_subclass',
        ],
    },
    user: {
        label: 'User',
        dimensions: ['audience_segment', 'age', 'gender'],
    },
    conversion: {
        label: 'Conversion',
        dimensions: ['conversion_lag'],
    },
};

export const metricCategories = {
    general: {
        label: 'General',
        metrics: ['impressions', 'clicks', 'ctr', 'unique_users'],
    },
    bids: {
        label: 'Bids',
        metrics: ['placed_bid', 'win_rate'],
    },
    cost: {
        label: 'Cost',
        metrics: [
            'owner_total_media_cost_local',
            'owner_total_media_cost_local_ecpm',
            'owner_total_media_cost_local_ecpc',
            'owner_inventory_cost_local',
            'owner_media_cost_2_local',
            'data_cost',
            'spend',
            'third_party_fees',
        ],
    },
    revenue: {
        label: 'Revenue',
        metrics: ['revenue', 'erpm', 'erpc', 'margin'],
    },
    engagements: {
        label: 'Engagements',
        metrics: [],
    },
    conversions: {
        label: 'Conversions',
        metrics: [],
    },
};

export function getAvailableDimensions() {
    const environmentSettings = getEnvironmentSettings();
    const canUseNewDimensions = environmentSettings.useNewReportingDimensions();

    const newDimensions = dimensions.slice(0);

    if (isInternalUser()) {
        newDimensions.push('camp_org');
    }

    if (canUseNewDimensions) {
        newDimensions.push('geo_city');
        newDimensions.push('fsa');
        newDimensions.push('device_type_name');
        newDimensions.push('device_language');
        newDimensions.push('device_carrier');
        newDimensions.push('exchange_id');
        newDimensions.push('inventory_type');
        newDimensions.push('inventory_id');
        newDimensions.push('inventory_name');
        newDimensions.push('app_store_cat');
        newDimensions.push('pmp_id');
        newDimensions.push('pmp_deal_name');
        newDimensions.push('creative_size');
        newDimensions.push('creative_channel');
        newDimensions.push('video_length');
        newDimensions.push('video_placement_name');
        newDimensions.push('video_position_name');
        newDimensions.push('conversion_lag');
        newDimensions.push('dooh_venue_type_subclass');
    }

    if (canUseNewDimensions && flags.isEnabled('en_3511_snowflake_dimensions_lower_priority')) {
        newDimensions.push('pmp_deal_source_type');
        newDimensions.push('audience_category');
        newDimensions.push('audience_subcategory');
    }
    return newDimensions;
}

export function getAvailableSnowflakeCategories() {
    const environmentSettings = getEnvironmentSettings();
    const canUseNewDimensions = environmentSettings.useNewReportingDimensions();

    if (canUseNewDimensions && flags.isEnabled('en_3511_snowflake_dimensions_lower_priority')) {
        return {
            ...categoriesSnowFlake,
            inventory: {
                label: 'Inventory',
                dimensions: [
                    'exchange_id',
                    'inventory_type',
                    'inventory_id',
                    'inventory_name',
                    'app_store_cat',
                    'pmp_id',
                    'pmp_deal_name',
                    'pmp_deal_source_type',
                    'video_placement_name',
                    'video_position_name',
                    'video_skippable',
                    'audience_category',
                    'audience_subcategory',
                ],
            },
        };
    }

    return categoriesSnowFlake;
}

export function getAvailableMetricCategories() {
    const environmentSettings = getEnvironmentSettings();
    const canUseNewDimensions = environmentSettings.useNewReportingDimensions();

    let newMetricCategories = { ...metricCategories };
    if (isInternalUser()) {
        newMetricCategories = {
            ...newMetricCategories,
            internalMetrics: {
                label: 'Internal Metrics',
                metrics: [
                    'addictive_cost_local',
                    'addictive_cost_cad',
                    'addictive_cost_usd',
                    'addictive_revenue_cad',
                    'internal_margin_cad',
                ],
            },
        };
    }

    if (canUseNewDimensions) {
        newMetricCategories = {
            ...newMetricCategories,
            general: {
                label: 'General',
                metrics: ['impressions', 'clicks', 'ctr', 'unique_users', 'vcr', 'revenue_ecpcv'],
            },
            cost: {
                label: 'Cost',
                metrics: [
                    'owner_total_media_cost_local',
                    'owner_total_media_cost_local_ecpm',
                    'owner_total_media_cost_local_ecpc',
                    'owner_total_media_cost_local_ecpcv',
                    'owner_inventory_cost_local',
                    'owner_media_cost_2_local',
                    'data_cost',
                    'spend',
                    'third_party_fees',
                ],
            },
            revenue: {
                label: 'Revenue',
                metrics: ['revenue', 'erpm', 'erpc', 'revenue_ecpcv', 'margin'],
            },
        };
    }

    return newMetricCategories;
}

export const METRICS = [
    // General
    { category: 'General', metricLabel: 'Impressions', metricKey: 'impressions' },
    { category: 'General', metricLabel: 'Clicks', metricKey: 'clicks' },
    { category: 'General', metricLabel: 'CTR', metricKey: 'ctr' },
    { category: 'General', metricLabel: 'Unique Users', metricKey: 'unique_users' },

    // Bids
    { category: 'Bids', metricLabel: 'Placed Bids', metricKey: 'placed_bid' },
    { category: 'Bids', metricLabel: 'Win Rate', metricKey: 'win_rate' },

    // Cost
    { category: 'Cost', metricLabel: 'Total Cost', metricKey: 'owner_total_media_cost_local' },
    {
        category: 'Cost',
        metricLabel: 'Total Cost eCPM',
        metricKey: 'owner_total_media_cost_local_ecpm',
    },
    {
        category: 'Cost',
        metricLabel: 'Total Cost eCPC',
        metricKey: 'owner_total_media_cost_local_ecpc',
    },
    { category: 'Cost', metricLabel: 'Inventory Cost', metricKey: 'owner_inventory_cost_local' },
    { category: 'Cost', metricLabel: 'Media Cost', metricKey: 'owner_media_cost_2_local' },
    { category: 'Cost', metricLabel: 'Data Cost', metricKey: 'data_cost' },
    { category: 'Cost', metricLabel: 'Data Cost - Audience', metricKey: 'tactic_cost_local' },
    { category: 'Cost', metricLabel: 'Data Cost - FTA', metricKey: 'fta_fee_local' },
    { category: 'Cost', metricLabel: 'Billable Cost', metricKey: 'owner_cost_local' },
    { category: 'Cost', metricLabel: 'Third Party Fees', metricKey: 'third_party_fees' },

    // Revenue
    { category: 'Revenue', metricLabel: 'Revenue', metricKey: 'revenue' },
    { category: 'Revenue', metricLabel: 'Revenue eCPM', metricKey: 'erpm' },
    { category: 'Revenue', metricLabel: 'Revenue eCPC', metricKey: 'erpc' },
    { category: 'Revenue', metricLabel: 'Margin', metricKey: 'margin' },

    // Internal Metrics
    {
        category: 'Internal Metrics',
        metricLabel: 'Internal Cost (Campaign Currency)',
        metricKey: 'addictive_cost_local',
    },
    {
        category: 'Internal Metrics',
        metricLabel: 'Internal Cost (CAD)',
        metricKey: 'addictive_cost_cad',
    },
    {
        category: 'Internal Metrics',
        metricLabel: 'Internal Cost (USD)',
        metricKey: 'addictive_cost_usd',
    },
    {
        category: 'Internal Metrics',
        metricLabel: 'Internal Revenue (CAD)',
        metricKey: 'addictive_revenue_cad',
    },
    {
        category: 'Internal Metrics',
        metricLabel: 'Internal Margin (CAD)',
        metricKey: 'internal_margin_cad',
    },
];

export function createMetricMenu(metrics) {
    const categoryOrder = _(metrics)
        .map(config => config.category)
        .union()
        .value();

    const metricsByCategory = _.groupBy(metrics, 'category');

    const metricMenu = _.map(categoryOrder, categoryLabel => ({
        categoryLabel,
        metrics: metricsByCategory[categoryLabel],
    }));

    return metricMenu;
}

export function getMetrics() {
    const environmentSettings = getEnvironmentSettings();
    const canUseNewDimensions = environmentSettings.useNewReportingDimensions();

    if (canUseNewDimensions) {
        return [
            ...METRICS,
            { category: 'General', metricLabel: 'VCR', metricKey: 'vcr' },
            {
                category: 'Cost',
                metricLabel: 'Total Cost eCPCV',
                metricKey: 'owner_total_media_cost_local_ecpcv',
            },
            { category: 'Revenue', metricLabel: 'Revenue eCPCV', metricKey: 'revenue_ecpcv' },
        ];
    }

    return METRICS;
}

export function findMetrics(metrics, metricKeys) {
    const metricKeysSubevents = metricKeys.filter(key => key.indexOf('|') > -1);

    const subeventsMetrics = metricKeysSubevents
        ? metricKeysSubevents.map(key => ({
              conversionName: key.split('|')[0],
              metricLabel: key.split('|')[1],
              metricKey: key,
              isSubCategory: true,
              category: 'Conversions',
          }))
        : [];

    return _.concat(
        _.filter(metrics, metric => _.includes(metricKeys, metric.metricKey)),
        subeventsMetrics
    );
}
